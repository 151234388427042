var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        name: "blow",
        mode: "out-in",
        "enter-active-class": "blow-enter-active blow",
        "leave-active-class": "blow-leave-active blow",
        appear: ""
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }