<template>
  <TransitionFade>
    <div
      class="BaseLoader"
      :class="[`type--${type}`]"
    >
      <div class="bars" />
    </div>
  </TransitionFade>
</template>

<script>
import TransitionFade from '@/transitions/TransitionFade';

const types = ['full', 'partial'];
export default {
  components: {
    TransitionFade,
  },

  props: {
    type: {
      type: String,
      default: 'full',
      validator: type => types.includes(type),
    },
  },
};
</script>

<style lang="scss" scoped>
.BaseLoader{
  &.type{
    &--full{
      --local-position: fixed;
      --local-background: linear-gradient(45deg, #{$color-neutral-lighter}, #{$color-neutral-lightest});
    }
    &--partial{
      --local-position: static;
      --local-background: linear-gradient(45deg, hsl(210deg 17% 95% / 14%), hsl(210deg 14% 97% / 26%));
    }
  }
  position: var(--local-position);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: $spacing-3xl;
  background: var(--local-background);

  transition: .3s color, .3s border, .3s transform, .3s opacity;

  .bars{
    position: relative;
    animation: loader 1s ease-in-out 0.4s infinite;

    $local-spacing: 1rem;
    $local-color: $color-primary;

    background: $local-color;
    width: $local-spacing;
    height: $local-spacing * 4;

    &:after,
    &:before {
      content: '';
      position: absolute;
      width: inherit;
      height: inherit;
      background: $local-color;
      animation: inherit;
    }

    &:before {
      right: $local-spacing * 2;

      animation-delay: 0.2s;
    }

    &:after {
      left: $local-spacing * 2;
      animation-delay: 0.6s;
    }
  }

  @keyframes loader {
    0%,
    100% {
      box-shadow: 0 0 0 $color-primary, 0 0 0 $color-primary;
    }
    50% {
      box-shadow: 0 -.25em 0 $color-primary, 0 .25em 0 $color-primary;
    }
  }
}
</style>
