export default {
  props: {
    value: {
      type: null,
      default: undefined,
    },
  },

  computed: {
    innerValue: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
