var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Statement" },
    [
      _c(
        _vm.statementType,
        _vm._g(
          _vm._b({ tag: "component" }, "component", _vm.$attrs, false),
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }