export default {
  computed: {
    i18n() {
      return this.$attrs?.i18n;
    },

    answerTag() {
      return this.i18n ? this.i18n : this.$attrs?.name;
    },
  },
};
