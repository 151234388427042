var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    _vm._b(
      {
        staticClass: "BaseButton",
        class: [
          _vm.themeClass,
          "variation--" + _vm.variation,
          "size--" + _vm.size
        ],
        attrs: { type: "button" },
        on: {
          click: function($event) {
            _vm.$attrs.disabled ? null : _vm.click()
          }
        }
      },
      "button",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }