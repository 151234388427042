export default {
  props: {
    theme: {
      type: String,
      default: undefined,
      validator: theme => ['light', 'dark'].includes(theme),
    },
  },

  computed: {
    themeClass() {
      return `theme--${this.theme ?? this.$settings.THEME}`;
    },
  },
};
