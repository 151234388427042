var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("TransitionFade", [
    _c("div", { staticClass: "BaseLoader", class: ["type--" + _vm.type] }, [
      _c("div", { staticClass: "bars" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }