var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "FileStatement", class: { empty: _vm.src === undefined } },
    [
      _vm.$te(_vm.labeli18n)
        ? _c("div", {
            staticClass: "label",
            domProps: { innerHTML: _vm._s(_vm.$t(_vm.labeli18n)) }
          })
        : _vm._e(),
      _vm.src
        ? _c("div", { staticClass: "iframe-content" }, [
            _c(
              "div",
              { staticClass: "iframe-content-header" },
              [
                _c("font-awesome-icon", {
                  staticClass: "expand",
                  attrs: { icon: ["fas", "expand"] },
                  on: {
                    click: function($event) {
                      return _vm.expand($event)
                    }
                  }
                }),
                _c("font-awesome-icon", {
                  staticClass: "compress",
                  attrs: { icon: ["fas", "compress"] },
                  on: {
                    click: function($event) {
                      return _vm.compress($event)
                    }
                  }
                })
              ],
              1
            ),
            _c("iframe", { attrs: { src: _vm.src, width: "100%" } })
          ])
        : _c("div", [_vm._v(" No file ")])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }